//-------------------------------------//
// MEDIA QUERIES
$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// COLORS

$background-color-body: #EBF1F6;
$color-body: #334150;

// Projects color palette
// TODO_REPLACE(projects own color palette)
$color-brand-blue: #00549F;
$color-brand-blue-light: #1E73BE;
$color-brand-blue-dark: #00549F;
$color-brand-blue-darker: #083863;
$color-brand-black: #10242B;
$color-brand-blacker: #0B0F12;
// TODO: update color palette, see Figma

$color-gray: #5C666A;
$color-lightgray: #EBF1F6;
$color-green: #35D367;
$color-red: #E63939;
$color-lightgray: #E1E8EE;

$color-link: $background-color-body;
$color-link-hover: lighten($background-color-body, 4%);

//-------------------------------------//
// FONTS

// TODO_REPLACE(projects font families)
$font-family-poppins: 'Poppins', Arial, Sans-Serif;
// $font-family-lato: 'Lato', Arial, Sans-Serif;
// $font-family-body: $font-family-poppins

$font-family-body: $font-family-poppins;
$font-color-body: $color-body;
$font-size-body: 14px;

$font-light: 300;
$font-normal: 400;
$font-medium: 600;
$font-bold: 700;
$font-bolder: 900;


//-------------------------------------//
// SPACING

$spacing: 20px;
$spacing-half: $spacing / 2;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;

//-------------------------------------//
// Global Variables

// Radius
// TODO_REPLACE(projects radiuses)
$border-radius-small: 3px;
$border-radius-medium: 5px;
$border-radius-large: 9px;

// Box Shadow
// TODO_REPLACE(projects shadows)
$box-shadow-light: 0 1px 3px rgba(#000, 0.12), 0 1px 2px rgba(#000, 0.24);
$box-shadow-medium: 0 3px 6px rgba(#000, 0.16), 0 3px 6px rgba(#000, 0.23);
$box-shadow-heavy: 0 10px 20px rgba(#000, 0.19), 0 6px 6px rgba(#000, 0.23);

//-------------------------------------//
// Component Variables
// TODO_REPLACE(project component styling)

// App Header
// $AppHeader-height: 60px;
// $AppHeader-background-color: gray;

// App Drawer
// $AppDrawer-width: 240px;
// $appDrawer-transition-duration: 160ms;

// ActivityIndicator
$ActivityIndicator-stroke: $color-brand-blue-light;

// CheckBox
// $CheckBox-border: 2px solid #E4F0FC;
// $CheckBox-color: #AE77EA;
// $CheckBox-checked-background-color: #AE77EA;

// RadioBox
// $RadioBox-border: 2px solid #E4F0FC;
// $RadioBox-hover-border: 7px solid #e9dcf7;
// $RadioBox-hover-background-color: rgba(#AE77EA, 0.5);
// $RadioBox-checked-border: 7px solid #e9dcf7;
// $RadioBox-checked-background-color: #AE77EA;

// ...
